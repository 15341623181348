

export const projectsData = [
    //
    {
        id: 1,
        projectName: "Biocoach",
        projectDesc: `Biocoach is the ultimate AI-powered nutrition companion! This is the future of food, where you'll get hyper-personalized meal plans, recipes, and grocery shopping lists based specifically on your lifestyle. In seconds, you'll get everything you need to feed your family, without all the hassle. Stop asking "What's for Dinner?", and instead, let Biocoach help you eat better. Pick your cravings, set your budget, and we'll take care of the rest!`,
        tags: ['React Native', 'Typescript', 'Redux', 'Open AI'],
        code: '',
        demo: 'https://apps.apple.com/us/app/biocoach-personal-nutrition/id6475170693',
        image: "https://i.ibb.co/MGKSnzK/biocoach.png"
    },
    {
        id: 2,
        projectName: "Diggy.app",
        projectDesc: "Diggy.app simplifies fleet management for yellow equipment asset owners, providing centralized data, real-time insights, and predictive maintenance to boost efficiency and minimize downtime.",
        tags: ['React Native', 'Typescript', 'Redux', 'Firebase', 'Offline Mode'],
        code: '',
        demo: 'https://apps.apple.com/us/app/diggy-app/id6639621300',
        image: "https://i.ibb.co/c1z03sQ/diggyfeaturegraphic.png"
    }, {
        id: 3,
        projectName: "COP28 UAE Official App",
        projectDesc: "I spearheaded the development of the COP28 UAE Official Application, an innovative platform lauded for its user-friendly interface and cutting-edge Wayfinder, powered by Mapbox. This app seamlessly guides attendees through the event, elevating their COP28 experience. With over 150,000 downloads and ranking #3 in the business category, this project stands as a testament to my expertise in mobile app development and UI design, delivering unparalleled functionality and user satisfaction.",
        tags: ['React Native', 'Typescript', 'Redux', 'Firebase', 'MapBox'],
        code: '',
        demo: '',
        image: "https://i.ibb.co/Bn4WcGf/cop28-feature.png"
    },
    {
        id: 4,
        projectName: "React Native HiveGPT",
        projectDesc: "I spearheaded the development of npm package for AI-powered chatbot for COP28 UAE Event info. Delivers event details and updates seamlessly.",
        tags: ['React Native', 'Typescript', 'Open AI'],
        code: '',
        demo: 'https://www.npmjs.com/package/react-native-hivegpt',
        image: "https://i.ibb.co/Bn4WcGf/cop28-feature.png"
    },
    {
        id: 5,
        projectName: "JINO - On-Demand Car Services",
        projectDesc: "Elevate your car care experience with JINO, your on-demand solution for premium automotive services. Say goodbye to the hassle of traditional car care, as JINO brings the expertise directly to your doorstep. Our environmentally conscious waterless car wash and deep cleaning services ensure a spotless and eco-friendly shine. From detailing to roadside assistance, trust JINO to prioritize your car's well-being with expert care and top-notch service, setting us apart with perfection, expertise, and a commitment to environmental sustainability.",
        tags: ['React Native', 'Redux', 'Thunk', 'AWS Amplify', 'Firebase'],
        code: '',
        demo: 'https://apps.apple.com/us/app/jino-on-demand-car-services/id1462862177',
        image: "https://i.ibb.co/GtCJks7/jino-Graphic.jpg"

    },
    {
        id: 6,
        projectName: "Mauka - Cricket Fantasy",
        projectDesc: "Experience cricket like never before with our Fantasy Cricket App! Play along each match, chat live, guess toss winners, and predict match victors. Unlock series through watching ads or by payment with Jazz option or credit/debit card. It's more than a game, it's a cricket celebration!",
        tags: ['React Native', 'Redux', 'Thunk', 'Firebase', 'Firestore'],
        code: '',
        demo: 'https://apps.apple.com/pk/app/mauka-cricket-fantasy/id6476555757',
        image: "https://i.ibb.co/WvrV2yY/mauka.png"
    },

    {
        id: 7,
        projectName: "Beautypass",
        projectDesc: "Beautypass is the largest community of fashion models in the world, present in every fashion capital on five continents. Membership is exclusively by invitation and only for models from verified agencies. Members enjoy access to thousands of offers and experiences every week from the latest and hottest businesses around in exchange for posting great content.",
        tags: ['React Native', 'Typescript', 'ContextApi', 'GraphQL'],
        code: '',
        demo: 'https://apps.apple.com/us/app/beautypass/id1169483314',
        image: "https://i.ibb.co/HVBGYyj/feature-Graphic.png"
    },
    {
        id: 8,
        projectName: "Moe Mow's Lawn Care",
        projectDesc: "Moe Mow's Lawn Care is a convenient, Uber-style app that connects clients with professional landscapers for on-demand lawn care services. With easy requests, seamless payments, and a focus on impeccable yard maintenance, our platform takes the hassle out of keeping your lawn pristine.",
        tags: ['React Native', 'Typescript', 'Redux Toolkit', 'Firebase'],
        code: '',
        demo: 'https://apps.apple.com/pk/app/moe-mows-lawn-care/id1471506728',
        image: "https://i.ibb.co/WFKTJH9/Moe-Mow-s-Android-Feature-Graphic.png"
    },
    {
        id: 9,
        projectName: 'Social27 Events',
        projectDesc: 'Social27 Events is a Virtual Event App helps you bring the best part of in-person events to the virtual world with greater reach, accessibility, flexibility, and insights than ever before.',
        tags: ['React Native', 'Typescript', 'Redux', 'Firebase'],
        code: '',
        demo: '',
        image: "https://i.ibb.co/ydFg1Db/unnamed.webp"
    },
    {
        id: 10,
        projectName: 'Houzzen',
        projectDesc: 'Houzzen is a portal for all students coming to Canada to find the best place for their stay that matches their specifications and requirements. Students can get in touch directly with the Landlord to finalize their deals.',
        tags: ['React Native', 'Typescript', 'Firebase', 'MobX'],
        code: '',
        demo: 'https://play.google.com/store/apps/details?id=com.houzzen',
        image: "https://i.ibb.co/gyrsgMX/1-1024x500.png"
    },
    {
        id: 11,
        projectName: 'Yarrow',
        projectDesc: "Yarrow provides On Demand Storage so your space doesn't limit your possibilities. We will store your items that don't fit well at your place and bring them back as you need them.Once you’re done, just let us know and we come pick them back up.",
        tags: ['React Native', 'Typescript', 'Firebase', 'MobX'],
        code: '',
        demo: 'https://play.google.com/store/apps/details?id=com.yarrow',
        image: "https://i.ibb.co/bX2R7L1/Cover-1-1024x500.jpg"
    },

    {
        id: 12,
        projectName: 'Instagram Clone',
        projectDesc: 'Instagram is a free, online photo-sharing application and social network platform that was acquired by Facebook in 2012.',
        tags: ['React Native', 'Typescript'],
        code: 'https://github.com/iamsaadMehmood/instagramClone',
        demo: '',
        image: 'https://9to5mac.com/wp-content/uploads/sites/6/2021/06/how-to-post-to-instagram-on-mac.jpg?quality=82&strip=all'
    },
    {
        id: 13,
        projectName: 'CV Card',
        projectDesc: 'This project is for sharing Digital Business card. In which user will get his digital business card from registered organization which he can share it with other users through QR code, Mail,SMS and by copying Link',
        tags: ['React Native', 'Typescript',],
        code: '',
        demo: 'https://play.google.com/store/apps/details?id=com.cvcard.app.prod',
        image: 'https://i.ibb.co/nkXGm6h/unnamed-1.webp'
    },
    {
        id: 14,
        projectName: 'Smart Guard',
        projectDesc: 'This project is specifically for Security Guards. In which Guard can see his duties. When his duty time is started his location will be tracked. Guard will receive notification if he is not in his location.',
        tags: ["React Native", 'TypeScript'],
        code: '',
        demo: 'https://play.google.com/store/apps/details?id=com.smartguard',
        image: ''
    },
    {
        id: 15,
        projectName: 'Labour Management System',
        projectDesc: 'This project is specifically for Labours. In which labour can see his duties. Labour will do check in at his duty time by Biometric. After check in his Location will be tracked until he will do check out or his duty time is over.',
        tags: ['Flutter', 'Dart'],
        code: '',
        demo: '',
        image: ''
    },


    {
        id: 16,
        projectName: 'Scrap Ready',
        projectDesc: 'In Australia there is a trend if anyone don’t want any item. He will place that item on his front door where any person who found that item useful can pick it up. So, Scrap Ready is an application for that purpose where user can post his unwanted items to give anyone who found it useful.',
        tags: ['React Native', 'Typescript', 'Redux', 'NestJS'],
        code: '',
        demo: '',
        image: ''
    },
]